import React from 'react';
import Layout from '../../components/layout';
import Seo from "../../components/seo";
import HeaderPage from '../../utils/HeaderPage';
import SimpleContentPage from '../../utils/SimpleContentPage'
import { useStaticQuery, graphql } from "gatsby";


const getdata = graphql`
{
  wpgraphql {
    page(id: "cG9zdDo4NzM0") {
      id
      title
      content
      uri
      seo {
        canonical
        metaDesc
        metaKeywords
        title
        twitterTitle
        twitterDescription
        opengraphDescription
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphTitle
        opengraphType
        opengraphImage {
          sourceUrl
        }
      }    
    }
  }
}
  `
const USPTO = () => {
    const data = useStaticQuery(getdata);
    const common = data.wpgraphql.page;
    return (
        <Layout>
            <Seo title={common.title} description={common.seo.metaDesc} canonical={common.uri} seo={common.seo}  />
            <HeaderPage headerTxt={common.title}></HeaderPage>
            <SimpleContentPage content={common.content}></SimpleContentPage>
        </Layout>
    );
};

export default USPTO;
